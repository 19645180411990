.input-main {
  padding-top: 30px;
  padding-left: 80px !important;
  padding-right: 80px !important;
  height: 70px !important;
}

@media (max-width: 768px) {
  .input-main {
    /* margin-top: 10px; */
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.input-div {
  display: flex !important;
  flex: 1 0 auto !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  flex-basis: auto !important;
  background-color: #ffffff !important;
  border-radius: 12px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  color: #222222 !important;
  height: 100% !important;
}
