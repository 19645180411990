.autocomplete-item {
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-info {
  padding-left: 10px;
  float: left;
}
.location-icon {
  margin-top: 6px;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  float: left;
}
strong {
  font-weight: bold;
}
.autocomplete-item:hover {
  background-color: #e9e9e9;
}
