.flex-item-desktop .city {
  width: 100%;
  margin-left: 15px;
}
.flex-item-mobile .city {
  width: 100%;
  margin-left: 0px;
  margin-top: 15px;
}
.city-item {
  width: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  padding: 15px;
  /* margin: 0 15px 15px 15px; */
  margin-bottom: 15px;
  border-radius: 12px !important;
  box-sizing: border-box;
}
.city-name {
  font-size: 18px;
  text-overflow: ellipsis;
}
.city-country {
  text-overflow: ellipsis;
}
.flex-item-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flex-item-desktop {
  display: flex;
  width: 100%;
}
.city-country {
  color: rgb(113, 113, 113) !important;
}
.city-img {
  border-radius: 8px !important;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.city-img-container {
  flex-shrink: 0;
  height: 200px;
  width: 300px;
}
.flex-item-mobile .city-img-container {
  width: 100% !important;
  flex: 0 0 100%;
  height: 100%;
}
.flex-item-mobile .city-img-container-container {
  padding-top: 66.6667%;
  contain: strict !important;
}
.flex-item-mobile .city-img {
  position: absolute !important;
  height: 100% !important;
  left: 0px !important;
  top: 0px !important;
  width: 100% !important;
  border-radius: 12px !important;
}
.title-info {
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
}
.city-distance {
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 0.04em !important;
  text-transform: uppercase !important;
}
.city-details {
  padding-top: 5px;
}
.city-detail {
  height: 28px;
  margin-top: 5px;
  display: flex;
}
.city-detail-name-container {
  display: flex;
  align-items: center;
}
.city-detail-name {
  vertical-align: middle;
  display: inline-block;
  padding-left: 5px;
}
.city-svg {
  margin-top: auto;
  margin-bottom: auto;
  width: 25px;
}
.city-detail-svg {
  max-height: 25px;
}
