.autocomplete-list {
  position: absolute;
  margin-left: 80px;
  margin-top: 5px;
  border-radius: 5px !important;
  z-index: 99;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  background-color: #fff;
  width: 350px;
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .autocomplete-list {
    margin-left: 30px;
  }
}
