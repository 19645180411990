.footer {
  position: relative;
  padding: 3rem 3rem 3rem 3rem;
  color: #8f959eba;
  font-size: 80%;
  left: 0;
  bottom: 0;
  width: 90%;
  margin-top: auto;
}

.footer hr {
  /* margin-right: 6rem; */
  border-color: #ffffff57;
  color: #8f959eba;
}

.footer p {
  padding-bottom: 5px;
}

.icon-attribution {
  padding-bottom: 5px;
}

.icon-attribution a {
  text-decoration: none;
  color: #8f959eba;
}

@media (max-width: 768px) {
  .footer {
    padding: 1rem !important;
  }

  .footer hr {
    /* margin-right: 2rem; */
    border-color: #ffffff57;
  }
}
