.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* #main-content {
  margin-left: 40px;
  margin-right: 40px;
}
*/
@media (min-width: 701px) {
  #main-content {
    display: flex;
  }
  .city-content-container {
    width: 700px !important;
  }
}
.city-content-container {
  /* flex: 0 0 55%; */
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

@media (min-width: 1128px) {
  .city-content-container {
    width: 840px !important;
  }
}
@media (min-width: 744px) {
  ._8h8epe3 {
    min-width: 547px !important;
  }
}

.main-content {
  /* height: 100%; */
  height: auto;
}
.hero-banner {
  height: 55vh;
  max-height: 500px;
  min-height: 350px;
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -63px;
}
