.message-text {
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #191919;
}

@media (max-width: 768px) {
  .message-text {
    margin-left: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}
.listing-container {
  display: flex;
}
@media (max-width: 700px) {
  .listing-container {
    display: block !important;
  }
}
@media (min-width: 701px) {
  #main-content {
    display: flex;
  }
  .city-content-container {
    width: 700px !important;
  }
}
.city-content-container {
  /* flex: 0 0 55%; */
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

@media (min-width: 1128px) {
  .city-content-container {
    width: 840px !important;
  }
}
@media (min-width: 744px) {
  ._8h8epe3 {
    min-width: 547px !important;
  }
}
#main-content {
  display: block;
  /* height: 100%; */
}
